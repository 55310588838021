import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import TrackCard from './trackCard'

// import { css } from '@emotion/react'
// import { colors } from '../styles/constants' 

const TracksList = (props) => {
    const data = useStaticQuery(graphql`
        query TracksListQuery {
            program: prismicProgram(uid: {eq: "videolab-premiere-rush"}) {
                uid
                data {
                  tracks {
                    track {
                      document {
                        ... on PrismicTrack {
                            ...TrackCardInfo
                        }
                      }
                    }
                  }
                }
            }
        }  
    `)

    const { closed } = props

    // const tracks = data.tracks.edges.map(edge => edge.node) 
    const tracks = data.program.data.tracks.map( ({track}) => track.document )

    return (
        <div>
            {tracks.map( track => (
                <TrackCard 
                    key={track.uid} 
                    closed={closed} 
                    uid={track.uid}
                    title={track.data.name.text}
                    excerpt={track.data.excerpt}
                    img={track.data.thumbnail.gatsbyImageData}
                    articles={track.data.articles && track.data.articles.map( ( {article} ) => {
                        // console.log("ARTICLE", article.document)

                        if ( article.document ) {
                            const tools = article.document.data.tools
                            // console.log('ARTICLE TOOLS', article)
                            return ({
                                'uid': article.document.uid,
                                'title': article.document.data.title.text,
                                'tools': tools.map( ({tool}) => {
                                    if ( tool.document !== null && tool.document.uid ) {
                                        return ({
                                            'uid': tool.document.uid,
                                            'name': tool.document.data.name.text,
                                            'logo': tool.document.data.logo.gatsbyImageData,
                                        })
                                      }
                                    return false
                                }),
                            })
                        }
                        return false
                    })}
                />
            ))}
        </div>
    )
}

export default TracksList