import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import ToolsList from './toolsList'

import { GiCardboardBox } from "react-icons/gi"
import { MdPlaylistPlay, MdCheckCircle } from "react-icons/md"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'

const TrackCard = (props) => {
    const {
        uid,
        title,
        excerpt,
        img,
        articles,
        closed,
    } = props

    // console.log(articles)

    return (
        <div className="card" css={Card} >
            <div css={main}>
                <div css={image}>
                    {img &&
                        <figure>
                            <GatsbyImage image={img} alt={title} />
                        </figure>
                    }
                    <small>#{uid}</small>
                </div>
                <div css={content}>
                    <div css={head}>
                        <h3>{title}</h3>                    
                        {excerpt && <p>{excerpt}</p>}
                    </div>

                    <div css={footer}>
                        {!closed &&
                            <Link to={`/modules/${uid}`} css={playlistLink}>
                                <MdPlaylistPlay /> Voir le module
                            </Link>
                        }
                        <div>
                            {articles && 
                                <div>{`${articles.length} articles`} <GiCardboardBox /></div>
                            }    
                        </div>
                    </div>
                </div>
            </div>

            <div css={articlesList}>
                {articles && articles.map( (article, index) => {
                    if (article.title) return (
                        <div css={listItem} key={article.uid}>
                            <div className="item--meta">
                                <div><MdCheckCircle /></div>
                                {closed ? 
                                    <h5>{index+1}. {article.title}</h5>
                                    :
                                    <h5><Link to={`/checklists/${article.uid}`}>{index+1}. {article.title}</Link></h5>
                                }
                            </div> 

                            <div className="item--tools">
                                {article.tools && <ToolsList tools={article.tools} article={article.uid} />} 
                            </div>
                        </div>
                    )
                    return null
                } )}
            </div>
        </div>
    )
}

export default TrackCard

/** CSS */

const Card = css`
    display: flex;
    margin-bottom: 40px;
    padding: 0 !important;

    @media screen and (max-width: ${colors.bp1}) {
        flex-wrap: wrap;
    }
`

const main = css`
    border-right: 1px solid #ebebeb;
    flex-basis: 40%;

    @media screen and (max-width: ${colors.bp1}) {
        flex-basis: 100%;
        border-right: none;
    }
`

const image = css`
    position: relative;

    small {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        padding: 0 4px;
        border-radius: 5px;
    }

    img {
        border-top-left-radius: 9px;
        margin: 0;

        @media screen and (max-width: ${colors.bp1}) {
            border-top-right-radius: 9px;
        }
    }
`

const content = css`
    padding: 0 20px 20px;
`

const head = css`
    h3 {
        margin-top: 20px;
    }
`

const footer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const articlesList = css`
    flex-basis: 60%;
    // max-height: 500px;
    // overflow-y: scroll;
    
    div:last-of-type {
        border-bottom: none;
    }
    
    @media screen and (max-width: ${colors.bp1}) {
        flex-basis: 100%;
        max-height: none;
    }
`

const listItem = css`
    min-height: 80px;
    padding: 5px 20px;
    border-bottom: 1px solid #ebebeb;
    line-height: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.item--meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        div {
            width: 20px;
            min-width: 20px;

            svg {
                margin-top: -4px;
                color: ${colors.lightgrey};
            }
        }

        h5 {
            margin: 2px 40px 0 10px;

            a {
                color: inherit;
        
                :hover {
                    text-decoration: none;
                }
            }
        }
    }

    .item--tools {
        display: flex;
    }
`

const playlistLink = css`
    padding: 8px 15px;
    border-radius: 5px;
    background-color: ${colors.whitegrey};
    transition: color .3s ease-in-out;
    color: ${colors.black};

    &:hover {
        text-decoration: none;
        color: ${colors.accent};
    }
`