import React from 'react'
import { useMember } from "@memberstack/react"

import Layout from '../components/layout'
import SeO from '../components/seo'
import TracksList from '../components/tracksList'

import { css } from '@emotion/react'
import { colors } from '../styles/constants' 

import { MdVerifiedUser } from "react-icons/md"
import StartCheckout from '../components/startCheckout'

import { offers } from "../utils/pricings"


const Dashboard = () => {
    const { member } = useMember()

    // React.useEffect(() => {
    //     console.log(member)
    // }, [member])

    return (
        <React.Fragment>
            <SeO 
                title="Dashboard"
            />

            <Layout type="dashboard">
                <div css={dashboardContainer}>
                    <h1>Dashboard</h1>
                    {member &&
                        <div>
                            <h4>Bienvenue {member.auth.email} ! <span><MdVerifiedUser /></span></h4>
                        </div>
                    }

                    {member && member.planConnections.length === 0 &&
                        <StartCheckout 
                            title="Vous n'avez pas encore de plan associé à ce compte !"
                            text="Débloquez le contenu complet de votre formation vidéo en reprenant votre inscription."
                            plans={offers.filter( offer => offer.id === "essentiel" )}
                        />
                    }
                    <h2>Votre programme :</h2>
                    <TracksList closed={false} />
                </div>
            </Layout>
        </React.Fragment>
    )
}

export default Dashboard

/**
 * 
 * CSS
 * 
 */

const dashboardContainer = css`
    margin-top: 50px;

    h4 {
        span {
            color: ${colors.accent3};
        }
    }

    .notice {
        .dashboard-email--verified, button {
            margin-left: 5px;
        }

        button {
            border-radius: 5px;
        }
    }
`